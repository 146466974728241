<template>
  <div class="nav_wrap">
    <img class="nav_txt" :src="bindColor == 'white' ? logoTxt_w : logoTxt_b" />
    <ul class="nav_list">
      <a v-for="(item, index) in navList" :key="index" :href="item.href">
        <li class="nav_item" :style="{color: bindColor}" :class="{ active: index === activeIndex }">
          {{ item.text }}
        </li>
      </a>
    </ul>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import "@/assets/font/font.css";
export default {
  name: "NavigationBar",
  props: { bindColor: String },
  setup() {
    const route = useRoute();
    const navList = ref([
      {
        text: "首页",
        href: "/",
      },
      {
        text: "超充驿站",
        href: "/SuperChangeStation",
      },
      {
        text: "合作加盟",
        href: "/JoininCooperation",
      },
      {
        text: "关于我们",
        href: "/AboutUs",
      },
    ]);
    const activeIndex = computed(() => {
      return navList.value.findIndex((item) => item.href === route.path);
    });
    const logoTxt_b = require("@/assets/images/nav/nav_txt_b.png");
    const logoTxt_w = require("@/assets/images/nav/nav_txt_w.png");
    function setActiveIndex(index) {
      activeIndex.value = index;
    }
    return {
      navList,
      activeIndex,
      logoTxt_b,
      logoTxt_w,
      setActiveIndex,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav_wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  // width: 1400px;
  // height: 106px;
  width: 72%;
  height: 106px;
  display: flex;
  justify-content: space-between;
  .nav_txt {
    width: 80px;
    object-fit: contain;
  }
  .nav_list {
    display: flex;
    align-items: center;
    .nav_item {
      position: relative;
      width: 86px;
      text-align: center;
      font-size: 14px;
      margin-right: 34px;
      transition: 0.3s;
      &:last-child {
        margin: 0;
      }
      img {
        display: none;
      }
    }
    a {
      text-decoration: none;
      color: black;
      margin-left: 54px;
    }
    .nav_item:hover {
      width: 86px;
      height: 31px;
      color: #fff;
      background: url("@/assets/images/nav/back.png");
      background-size: 86px 31px;
      font-family: "myfont";
      text-align: center;
      line-height: 31px;
    }
  }
}
.active {
  width: 86px;
  height: 31px;
  color: #fff;
  background: url("@/assets/images/nav/back.png");
  background-size: 86px 31px;
  font-family: "myfont";
  text-align: center;
  line-height: 31px;
}
</style>
