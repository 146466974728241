<template>
  <div class="container">
    <NavigationBar bindColor="block"></NavigationBar>
    <div class="banner"></div>
    <div class="advice-box">
      <div class="titleBar">
        <img src="@/assets/images/common/icon_l.png" />
        <h1>超充驿站</h1>
        <img src="@/assets/images/common/icon_r.png" />
      </div>
      <div class="advice_content">
        <div class="advice_item">
          <img src="@/assets/images/Index/place1.png" />
          <div class="txt">
            <p>液冷快充</p>
            <p>即充即走</p>
          </div>
        </div>
        <div class="advice_item">
          <img src="@/assets/images/Index/place2.png" />
          <div class="txt">
            <p>液冷快充</p>
            <p>即充即走</p>
          </div>
        </div>
        <div class="advice_item">
          <img src="@/assets/images/Index/place3.png" />
          <div class="txt">
            <p>液冷快充</p>
            <p>即充即走</p>
          </div>
        </div>
        <div class="advice_item">
          <img src="@/assets/images/Index/place4.png" />
          <div class="txt">
            <p>液冷快充</p>
            <p>即充即走</p>
          </div>
        </div>
        <div class="advice_item">
          <img src="@/assets/images/Index/place5.png" />
          <div class="txt">
            <p>液冷快充</p>
            <p>即充即走</p>
          </div>
        </div>
        <div class="advice_item">
          <img src="@/assets/images/Index/place6.png" />
          <div class="txt">
            <p>液冷快充</p>
            <p>即充即走</p>
          </div>
        </div>
      </div>
      <div class="learn_more">
        了解更多 <img src="@/assets/images/common/right_arrow.png" />
      </div>
    </div>
    <div class="content">
      <div class="titleBar">
        <img src="@/assets/images/common/icon_l.png" />
        <h1>九州超充移动端</h1>
        <img src="@/assets/images/common/icon_r.png" />
      </div>
      <div class="carousel">
        <CarouselMap
          :listBanner="listBanner"
          :interval="3000"
          :setHeight="772"
          :autoSwitch="true"
        ></CarouselMap>
      </div>
    </div>
    <div class="win_box">
      <img class="win_img" src="@/assets/images/Index/win.png" />
      <p>
        <img src="@/assets/images/Index/icon1.png" /><span
          >联系电话：1525151651</span
        >
      </p>
      <div class="leanMore_btn">了解更多</div>
    </div>
    <BottomNavBar></BottomNavBar>
  </div>
</template>

<script>
import NavigationBar from "../components/NavigationBar.vue";
import BottomNavBar from "../components/BottomNavBar.vue";
import CarouselMap from "@/components/CarouselMap.vue";
import { ref } from "vue";
export default {
  name: "IndexView",
  components: {
    NavigationBar,
    BottomNavBar,
    CarouselMap,
  },
  setup() {
    const listBanner = ref([
      { img: require("@/assets/images/Index/lunbo1.png") },
      { img: require("@/assets/images/Index/lunbo2.png") },
    ]);
    return {
      listBanner,
    };
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 1085px;
  background: url("@/assets/images/Index/bananer.png") no-repeat;
  // background-size: contain;
  background-position: top;
}
.advice-box {
  width: 1400px;
  height: auto;
  margin: 0 auto;
  margin-top: -13%;
  // margin-top: -260px;

  .titleBar {
    margin-bottom: 26px;
  }
  .advice_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .advice_item {
      // width: 410px;
      width: 29%;
      height: 188px;
      background: #f2f9ff;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        height: 115px;
        margin-right: 42px;
      }
      p {
        font-family: $otherFont;
        font-weight: bold;
        font-size: 28px;
        color: #000000;
      }
    }
  }
  .learn_more {
    padding-bottom: 32px;
    color: #0c00ff;
    font-family: $themeFont;
    font-weight: bold;
    font-size: 18px;
    text-align: right;
    img {
      width: 16px;
      height: 10px;
    }
  }
}

.content {
  width: 100%;
  height: 1124px;
  box-sizing: border-box;
  background: url("@/assets/images/Index/content-bg.png") no-repeat;
  background-size: 100% 100%;
  background-position: top;
  .titleBar {
    padding: 99px 0 84px 0;
  }
  .carousel {
    width: 1312px;
    height: 772px;
    // background: url("@/assets/images/Index/lunbo1.png") no-repeat;
    // background-size: 100% 100%;
    // background-position: top;
    margin: 0 auto;
  }
}
.win_box {
  width: 100%;
  height: 317px;
  background: url("@/assets/images/Index/aaa.png") no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .win_img {
    width: 180px;
    height: 35px;
  }
  p {
    img {
      width: 21px;
      height: 21px;
      margin-right: 6px;
    }
    font-size: 18px;
    color: #fff;
    padding: 24px 0;
    font-family: $otherFont;
  }
  .leanMore_btn {
    width: 88px;
    height: 37px;
    border-radius: 6px;
    border: 1px solid #ffffff;
    font-size: 14px;
    color: #fff;
    font-family: $otherFont;
    text-align: center;
    line-height: 37px;
    cursor: pointer;
  }
}
</style>