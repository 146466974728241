<template>
  <div class="footer_wrap">
    <img src="@/assets/images/common/logo_w.png" alt="" />
    <p class="call">联系我们</p>
    <div class="downLoad_box">
      <div class="down_item">
        <img src="@/assets/images/common/app.png" />
        <p>APP</p>
      </div>
      <div class="down_item">
        <img src="@/assets/images/common/applet.png" />
        <p>小程序</p>
      </div>
      <div class="down_item">
        <img src="@/assets/images/common/offAccount.png" />
        <p>公众号</p>
      </div>
    </div>
    <p class="txt">
      <img src="@/assets/images/Index/icon1.png" /><span
        >联系电话：18526945222</span
      >
    </p>
    <p class="txt">
      <img src="@/assets/images/Index/icon2.png" /><span
        >公司地址：北京市海淀区丰慧中路新材料创业大厦B-526</span
      >
    </p>
    <div class="record">
      Copyright © 2023-至今 版权所有：九州和为 （北京）科技发展有限公司
      <a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank"
        >京ICP备2023018762号</a
      >
      <img src="../assets/images/common/beian.png" />
      <a
        href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802044173"
        rel="noreferrer"
        target="_blank"
        >京公网安备11010802044173</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomNavBar",
};
</script>

<style lang="scss" scoped>
.footer_wrap {
  position: relative;
  width: 100%;
  height: 683px;
  box-sizing: border-box;
  padding: 94px 0 39px 259px;
  background: #000000;
  color: #ffffff;
  font-family: $themeFont;
  font-size: 14px;
  .call {
    font-size: 18px;
    margin: 66px 0 30px 0;
  }
  .downLoad_box {
    display: flex;
    margin-bottom: 50px;
    .down_item {
      margin-right: 36px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      img {
        margin-bottom: 32px;
      }
    }
  }
  .record {
    position: absolute;
    text-align: center;
    bottom: 39px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    a {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
    }
    img{
      margin: 0 5px;
    }
  }
  .txt {
    padding-bottom: 14px;
    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
    span {
      padding-left: 10px;
      vertical-align: middle;
    }
  }
}
</style>