<template>
  <div class="container">
    <NavigationBar></NavigationBar>
    <!-- <main style="margin-top: 100px"></main> -->
    <div class="box_header"></div>
    <div class="box_con">
      <div class="box_con_head">
        <!-- <img src="@/assets/images/superchange/advantage.png" alt="" /> -->
        <div class="superiority">
          <img src="@/assets/images/common/icon_l.png" alt="" />
          <p>超充驿站的优势</p>
          <img src="@/assets/images/common/icon_r.png" alt="" />
        </div>
      </div>
      <div class="advantage">
        <div class="advan-box">
          <div class="advantage_left">
            <div class="advantage_left_con1">
              <div class="title">分体式充电枪</div>
              <span
                >充电枪与主机分离，主机可扩容。场站升级改造无需二次动工。</span
              >
            </div>
            <div class="advantage_left_con2">
              <div class="advantage_left_con2_item1">
                <div class="title">设施完善</div>
                <span
                  >根据场地属性与用户<br />特性，配备合适设施，<br />增加场站收益。</span
                >
              </div>
              <div class="advantage_left_con2_item2">
                <div class="title">即充即走</div>
                <span
                  >液冷主机+液冷枪<br />实现高压快充 ,可<br />达600KW充电功<br />率。</span
                >
              </div>
            </div>
            <div class="advantage_left_con2">
              <div class="advantage_left_con3_item1">
                <div class="title">定制化场站</div>
                <span
                  >根据场站功能,以<br />及服务群体的不<br />同对场站进行定<br />制。</span
                >
              </div>
              <div class="advantage_left_con3_item2">
                <div class="title">适度超前</div>
                <span
                  >部分超充600KW适配增量车型，
                  <br />剩余快充480KW满足当前存<br />量车型。</span
                >
              </div>
            </div>
          </div>
          <div class="advantage_right">
            <div class="advantage_right_item1">
              <div class="title">数字储能</div>
              <span>增加储能，削峰填谷节省电费，稳定电站<br />充电功率。</span>
            </div>
            <div class="advantage_right_item2">
              <div class="title">功率池化</div>
              <span
                >动态调动场站内各个枪的功率，只能调度<br />用电，提高市电利用率。</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_con_two">
      <div class="boxtwo">
        <div class="superiority">
          <img src="@/assets/images/common/icon_l.png" alt="" />
          <p>三位一体打造数字化智能场站</p>
          <img src="@/assets/images/common/icon_r.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box_con_three">
      <div class="box_three">
        <div class="boxtwo">
          <div class="superiority">
            <img src="@/assets/images/common/icon_l.png" alt="" />
            <p>多场景合作建站</p>
            <img src="@/assets/images/common/icon_r.png" alt="" />
          </div>
        </div>
      </div>
      <div class="advice_content">
        <div class="advice_con">
          <div class="advice_item">
            <img src="@/assets/images/superchange/stand1.png" />
            <p>专属公交充电站</p>
          </div>
          <div class="advice_item">
            <img src="@/assets/images/superchange/stand2.png" />
            <p>高速服务区充电站</p>
          </div>
          <div class="advice_item">
            <img src="@/assets/images/superchange/stand3.png" />
            <p>停车场充电站</p>
          </div>
          <div class="advice_item">
            <img src="@/assets/images/superchange/stand4.png" />
            <p>加油站充电站</p>
          </div>
        </div>
        <div class="advice_con">
          <div class="advice_item">
            <img src="@/assets/images/superchange/stand5.png" />
            <p>物流专属充电站</p>
          </div>
          <div class="advice_item">
            <img src="@/assets/images/superchange/stand6.png" />
            <p>政府专属充电站</p>
          </div>
          <div class="advice_item">
            <img src="@/assets/images/superchange/stand7.png" />
            <p>客运专属充电站</p>
          </div>
          <div class="advice_item">
            <img src="@/assets/images/superchange/stand8.png" />
            <p>景区专属充电站</p>
          </div>
        </div>
      </div>
    </div>
    <BottomNavBar></BottomNavBar>
  </div>
</template>

<script>
import NavigationBar from "../components/NavigationBar.vue";
import BottomNavBar from "../components/BottomNavBar.vue";
export default {
  name: "SuperChangeStation",
  components: {
    NavigationBar,
    BottomNavBar,
  },
};
</script>

<style lang="scss" scoped>
.box_header {
  width: 100%;
  height: 650px;
  background: url("@/assets/images/superchange/headback.png");
  background-size: 100% 650px;
}
.box_con {
  width: 100%;
  height: 987px;
  background: #e4f3ff;
  overflow: hidden;
}
.box_con_head {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 61px;
}
.superiority {
  display: flex;
  align-items: center;
  img {
    width: 36px;
    height: 30px;
  }
  p {
    font-size: 40px;
    font-weight: bold;
    margin-left: 80px;
    margin-right: 80px;
  }
}
.advantage {
  width: 100%;
  margin-top: 33px;
  box-sizing: border-box;
  display: flex;
}
.advan-box {
  /* width: 100%; */
  display: flex;
  margin: auto;
}
.advantage_left {
  width: 922px;
}
.advantage_left_con1 {
  width: 922px;
  height: 242px;
  background: url("@/assets/images/superchange/advantage1.png");
  background-size: 922px 242px;
  padding: 56px 0 0 46px;
  box-sizing: border-box;
}
.advantage_left_con2 {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}
.advantage_left_con2_item1 {
  width: 451px;
  height: 242px;
  background: url("@/assets/images/superchange/advantage2.png");
  background-size: 451px 242px;
  padding: 56px 0 0 46px;
  box-sizing: border-box;
}
.advantage_left_con2_item2 {
  width: 451px;
  height: 242px;
  background: url("@/assets/images/superchange/advantage3.png");
  background-size: 451px 242px;
  padding: 56px 0 0 46px;
  box-sizing: border-box;
}
.advantage_left_con3_item1 {
  width: 304px;
  height: 242px;
  background: url("@/assets/images/superchange/advantage4.png");
  background-size: 304px 242px;
  padding: 56px 0 0 46px;
  box-sizing: border-box;
}
.advantage_left_con3_item2 {
  width: 599px;
  height: 242px;
  background: url("@/assets/images/superchange/advantage5.png");
  background-size: 599px 242px;
  padding: 56px 0 0 46px;
  box-sizing: border-box;
}
.advantage_right {
  /* width: 100%; */
  margin-left: 21px;
}
.advantage_right_item1 {
  width: 457px;
  height: 337px;
  background: url("@/assets/images/superchange/right1.png");
  background-size: 457px 337px;
  padding: 56px 0 0 46px;
  box-sizing: border-box;
}
.advantage_right_item2 {
  width: 457px;
  height: 412px;
  background: url("@/assets/images/superchange/right2.png");
  background-size: 457px 412px;
  margin-top: 17px;
  padding: 56px 0 0 46px;
  box-sizing: border-box;
}
.title {
  font-size: 22px;
  color: #000000;
  font-weight: bold;
}
span {
  font-size: 18px;
}
.box_con_two {
  width: 100%;
  height: 1023px;
  background: url("@/assets/images/superchange/band.png");
  background-size: 100% 1023px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .boxtwo {
    margin-top: 67px;
  }
}
.box_con_three {
  width: 100%;
  height: 852px;
  background: #e4f3ff;
  overflow: hidden;
  .box_three {
    display: flex;
    justify-content: center;
    margin-top: 91px;
  }
  .advice_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 150px;
    padding-right: 150px;
    box-sizing: border-box;
    .advice_con {
      display: flex;
      justify-content: space-evenly;
      margin-top: 50px;
    }
    .advice_item {
      width: 278px;
      margin-bottom: 46px;
      display: flex;
      flex-direction: column;
      text-align: center;
      img {
        width: 277px;
        height: 194px;
      }
      p {
        font-family: $otherFont;
        font-weight: bold;
        font-size: 22px;
        color: #000000;
        margin-top: 26px;
      }
    }
  }
}
</style>
