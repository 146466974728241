<template>
  <div class="container">
    <NavigationBar></NavigationBar>
    <div class="banner">
      <img src="@/assets/images/cooperation/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="titleBar">
        <img src="@/assets/images/common/icon_l.png" />
        <h1>为什么选择九州超充</h1>
        <img src="@/assets/images/common/icon_r.png" />
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <img :src="item.imgUrl" />
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <div class="teamwork-box">
      <div class="titleBar">
        <img src="@/assets/images/common/icon_l.png" />
        <h1>合作类型</h1>
        <img src="@/assets/images/common/icon_r.png" />
      </div>
      <div class="coop">
        <div class="box1">
          <h1>资本合作</h1>
          <ul>
            <li>项目投资</li>
            <li>战略投资</li>
            <li>基金合作</li>
          </ul>
        </div>
        <div class="box2">
          <h1>资源合作</h1>
          <ul>
            <li>市级代理</li>
            <li>省级代理</li>
            <li>海外代理</li>
          </ul>
        </div>
      </div>
      <p class="phone">
        <img src="@/assets/images/cooperation/icon1.png" /><span
          >联系电话：18526945222</span
        >
      </p>
      <p class="txt">投资有风险，加盟需谨慎</p>
    </div>
    <BottomNavBar></BottomNavBar>
  </div>
</template>

<script>
import NavigationBar from "../components/NavigationBar.vue";
import BottomNavBar from "../components/BottomNavBar.vue";
import { reactive, ref, onMounted, computed } from "vue";
export default {
  name: "JoininCooperation",
  components: {
    NavigationBar,
    BottomNavBar,
  },
  setup() {
    const list = reactive([
      {
        text: "快速开站",
        imgUrl: require("@/assets/images/cooperation/place1.png"),
      },
      {
        text: "设备先进",
        imgUrl: require("@/assets/images/cooperation/place2.png"),
      },
      {
        text: "24小时服务",
        imgUrl: require("@/assets/images/cooperation/place3.png"),
      },
      {
        text: "团队齐全",
        imgUrl: require("@/assets/images/cooperation/place4.png"),
      },
      {
        text: "定制化解决方案",
        imgUrl: require("@/assets/images/cooperation/place5.png"),
      },
      {
        text: "投资产出规划",
        imgUrl: require("@/assets/images/cooperation/place6.png"),
      },
    ]);
    return { list };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  img {
    width: 100%;
    height: 760px;
  }
}
.content {
  box-sizing: border-box;
  padding: 50px 260px 65px 260px;
  background: #e4f3ff;
  .titleBar {
    margin-bottom: 60px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      // width: 450px;
      width: 32%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-right: 24px;
      // &:nth-child(3n) {
      //   margin-right: 0;
      // }
      img {
        width: 100%;
        height: auto;
      }
      span {
        margin-top: 10px;
        margin-bottom: 15px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 22px;
        color: #000000;
      }
    }
  }
}
.teamwork-box {
  height: 879px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 67px 0;
  .coop {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .box1 {
      // width: 30%;
      border-radius: 15px;
      width: 600px;
      height: 500px;
      margin-right: 25px;
      box-sizing: border-box;
      padding: 78px 100px;
      background: url("@/assets/images/cooperation/bg1.png") no-repeat;
      h1 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 42px;
        color: #000000;
        margin-bottom: 29px;
      }
      ul {
        list-style: inside;
        li {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 33px;
          color: #666666;
          margin-bottom: 29px;
          &::marker {
            color: #c194ff;
          }
        }
      }
    }
    .box2 {
      // width: 30%;
      border-radius: 15px;
      width: 600px;
      height: 500px;
      box-sizing: border-box;
      padding: 78px 100px;
      background: url("@/assets/images/cooperation/bg2.png") no-repeat;
      h1 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 42px;
        color: #000000;
        margin-bottom: 29px;
      }
      ul {
        list-style: inside;
        li {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 33px;
          color: #666666;
          margin-bottom: 29px;
          &::marker {
            color: #89cae7;
          }
        }
      }
    }
  }
  .phone {
    text-align: center;
    margin-top: 85px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #000000;
    margin-bottom: 26px;
    img {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
  }
  .txt {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #0062ff;
    text-align: center;
  }
}
</style>