<template>
  <div class="container">
    <NavigationBar bindColor="white"></NavigationBar>
    <div class="banner">
      <img src="@/assets/images/aboutUs/banner.png" />
    </div>
    <div class="info_box">
      <div class="titleBar">
        <img src="@/assets/images/common/icon_l.png" />
        <h1>关于我们</h1>
        <img src="@/assets/images/common/icon_r.png" />
      </div>
      <div class="txt">
        <h1>九州超充</h1>
        <p class="p456">
          九州超充是由九州和为
          （北京）科技发展有限公司研发的充电桩运营服务平台。
          九州超充携手地方政府与充电产业链上下游，依据各地基础设施，打造健全的第
          三方合作平台，为全国各城市提供全方位的能源管理和充电服务。
        </p>

        <p class="p416">
          我公司以“液冷超级快充”为核心，通过“九州超充”App联合线下运营商和各个站点站点，构建绿色出行网，便民利民惠民。
        </p>

        <p class="p366">
          九州超充积极响应国家号召，投入到拉动新能源汽车消费和充电
          网络的建设中。九州超充的目标是：让国家放心，让国民满意的
          超充新能源充电网络。九州超充的使命是：实现新能源车充新能
          源电力供给，让尾气和雾霾远离人类。
        </p>
      </div>
    </div>
    <div class="company">
      <div class="item item1">
        <div class="txt">
          <h2>企业目标</h2>
          <p>响应国家新能源汽车发展推广超充</p>
        </div>
      </div>
      <div class="item item2">
        <div class="txt">
          <h2>企业愿景</h2>
          <p>打造便捷高效的全国绿色超充网络</p>
        </div>
      </div>
      <div class="item item3">
        <div class="txt">
          <h2>企业理念</h2>
          <p>团结、高效、科技、环保、清洁</p>
        </div>
      </div>
    </div>
    <div class="lead-box">
      <div class="item" v-for="(item, index) in leadList" :key="index">
        <img :src="item.imgUrl" />
        <h3>{{ item.title }}</h3>
        <p>{{ item.info }}</p>
      </div>
    </div>
    <div class="slogan-box"></div>
    <div class="catch-phrase">
      <div class="slogan slogan1">
        <div class="txt">
          <h1>“多”</h1>
          <p>九州超充致力于在全国范围内逐渐 铺设超充网络。</p>
        </div>
      </div>
      <div class="slogan slogan2">
        <div class="txt">
          <h1>“快”</h1>
          <p>一秒钟一公里，一杯咖啡的时问， 爱车满电出行。</p>
        </div>
      </div>
      <div class="slogan slogan3">
        <div class="txt">
          <h1>“好”</h1>
          <p>不同车型匹配不同电压电湾，为 您的安全保驾护航。</p>
        </div>
      </div>
      <div class="slogan slogan4">
        <div class="txt">
          <h1>“省”</h1>
          <p>时间就是金钱，九州超充让您拥有 更多时间金钱。</p>
        </div>
      </div>
    </div>
    <BottomNavBar></BottomNavBar>
  </div>
</template>

<script>
import { reactive } from "vue";
import NavigationBar from "../components/NavigationBar.vue";
import BottomNavBar from "../components/BottomNavBar.vue";
export default {
  name: "AboutUs",
  components: {
    NavigationBar,
    BottomNavBar,
  },
  setup() {
    const leadList = reactive([
      {
        imgUrl: require("@/assets/images/aboutUs/icon1.png"),
        title: "扫码充电",
        info: "一键扫码开启充电",
      },
      {
        imgUrl: require("@/assets/images/aboutUs/icon2.png"),
        title: "高效快充",
        info: "充电一秒钟续航一公里",
      },
      {
        imgUrl: require("@/assets/images/aboutUs/icon3.png"),
        title: "找站便捷",
        info: "打开定位显示附近充电桩",
      },
      {
        imgUrl: require("@/assets/images/aboutUs/icon4.png"),
        title: "价格优惠",
        info: "计费明晰准确不乱收费",
      },
      {
        imgUrl: require("@/assets/images/aboutUs/icon5.png"),
        title: "多种服务",
        info: "餐饮、雨棚、卫生间等",
      },
      {
        imgUrl: require("@/assets/images/aboutUs/icon6.png"),
        title: "快速提现",
        info: "一键提现安全快速",
      },
    ]);
    return {
      leadList,
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  height: 858px;
}
.info_box {
  height: 942px;
  box-sizing: border-box;
  background: url("@/assets/images/aboutUs/bg1.png") no-repeat;
  .titleBar {
    padding-top: 66px;
    padding-bottom: 92px;
  }
  .txt {
    padding-left: 260px;
    h1 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 50px;
      color: #000000;
      margin-bottom: 40px;
    }
    p {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #666666;
      margin-bottom: 40px;
      text-align: justify;
      line-height: 44px;
    }
    .p456 {
      width: 707px;
    }
    .p416 {
      width: 700px;
    }
    .p366 {
      width: 580px;
    }
  }
}
.company {
  width: 100%;
  height: 666px;
  background: #ebf4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  .item {
    position: relative;
    width: 490px;
    height: 470px;
    .txt {
      position: absolute;
      bottom: 84px;
      left: 64px;
      h2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 27px;
        color: #0042da;
        margin-bottom: 10px;
      }
      p {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 22px;
        color: #666666;
      }
    }
    &.item1 {
      background: url("@/assets/images/aboutUs/bg2.png");
    }
    &.item2 {
      background: url("@/assets/images/aboutUs/bg3.png");
    }
    &.item3 {
      background: url("@/assets/images/aboutUs/bg4.png");
    }
  }
}
.lead-box {
  width: 100%;
  height: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
  .item {
    // width: 260px;
    width: 13.5%;
    text-align: center;
    img {
      width: 100px;
      height: 100px;
      margin-bottom: 14px;
    }
    h3,
    p {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #666666;
    }
    h3 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #000000;
      line-height: 33px;
    }
  }
}
.catch-phrase {
  width: 100%;
  height: auto;
  background: #ebf4ff;
  padding-top: 143px;
  padding-bottom: 172px;
  .slogan {
    position: relative;
    // width: 73%;
    // height: 519px;
    width: 1400px;
    height: 519px;
    margin: 0 auto;
    .txt {
      h1 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 49px;
        color: #0042da;
        margin-bottom: 20px;
      }
      p {
        width: 375px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 25px;
        color: #666666;
        line-height: 41px;
      }
    }
    &.slogan1 {
      background: url("@/assets/images/aboutUs/place1.png") no-repeat;
      margin-bottom: 130px;
      .txt {
        position: absolute;
        left: 5%;
        top: 33%;
        h1 {
          margin-left: -25px;
        }
      }
    }
    &.slogan2 {
      background: url("@/assets/images/aboutUs/place2.png") no-repeat;
      margin-bottom: 130px;
      .txt {
        position: absolute;
        right: 6%;
        top: 33%;
        h1 {
          margin-left: -25px;
        }
      }
    }

    &.slogan3 {
      background: url("@/assets/images/aboutUs/place3.png") no-repeat;
      margin-bottom: 130px;
      .txt {
        position: absolute;
        left: 5%;
        top: 33%;
        h1 {
          margin-left: -25px;
        }
      }
    }

    &.slogan4 {
      background: url("@/assets/images/aboutUs/place4.png") no-repeat;
      .txt {
        position: absolute;
        right: 5%;
        top: 33%;
        h1 {
          margin-left: -25px;
        }
      }
    }
  }
}
</style>