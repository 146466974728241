import { createRouter, createWebHistory } from "vue-router";
import IndexView from "../views/IndexView.vue";
import SuperChangeStation from "../views/SuperChangeStation.vue";
import JoininCooperation from "../views/JoininCooperation.vue";
import AboutUs from "../views/AboutUs.vue";


const routes = [
  {
    path: "/",
    name: "IndexView",
    component: IndexView,
  }, {
    path: "/SuperChangeStation",
    name: "SuperChangeStation",
    component: SuperChangeStation,
  }, {
    path: "/JoininCooperation",
    name: "JoininCooperation",
    component: JoininCooperation,
  }, {
    path: "/AboutUs",
    name: "AboutUs",
    component: AboutUs,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
